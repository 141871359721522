import * as $ from 'jquery';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import {
  Injectable,
  LOCALE_ID,
  NgModule,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { environment } from './../environments/environment';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import {
  AppSidebarComponent,
  LeaveDialog,
} from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { LoginComponent } from './login/login.component';

import { NgStrapiAuthModule } from './services/strapi/auth/ng-strapi-auth.module';
import { NgStrapiJwtInterceptorService } from './services/strapi/auth/ng-strapi-jwt-interceptor.service';
import { NgStrapiErrorInterceptor } from './services/strapi/auth/ng-strapi-error-interceptor.service';

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';


import { StatisticsComponent } from './dashboard/statistics/statistics.component';
import { RegisterComponent } from './login/register/register.component';
import { AcceptInviteComponent } from './login/accept-invite/accept-invite.component';
import { ForgotComponent } from './login/forgot/forgot.component';
import { ConfirmEmailComponent } from './login/confirm-email/confirm-email.component';
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { FooterComponent } from './login/footer/footer.component';
import { PrivacyComponent } from './terms/privacy/privacy.component';
import { ServiceTermsComponent } from './terms/service-terms/service-terms.component';

import { EmptyComponent } from './layouts/empty/empty.component';
import { GoogleComponent } from './login/external/google/google.component';
import { ServiceWorkerModule } from '@angular/service-worker';

import { SafePipe } from './dashboard/statistics/components/grafana-charts-view/grafana-charts-view.component';


import { MyProfileSettingsComponent } from './dashboard/settings/components/my-profile-settings/my-profile-settings.component';
import { UsersSettingsComponent } from './dashboard/settings/components/users-settings/users-settings.component';
import {
  DeleteUserDialogComponent,
  InviteUserDialogComponent,
} from './dashboard/settings/components/users-settings/users-settings.component';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  NativeDateAdapter,
} from '@angular/material/core';
import { Platform } from '@angular/cdk/platform';
import { HeaderExtraComponent } from './layouts/full/header-extra/header-extra.component';
import { SettingsComponent } from './dashboard/settings/settings.component';
import { TopBarSubComponent } from './shared/top-bar-sub/top-bar-sub.component';
import { CompareWithComponent } from './dashboard/statistics/components/compare-with/compare-with.component';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { LoadingComponent } from './shared/loading/loading.component';
import { VenueSettingsComponent } from './dashboard/settings/components/venue-settings/venue-settings.component';
import { StandaloneLivePageComponent } from './dashboard/standalone-live-page/standalone-live-page.component';
import '@fontsource/montserrat';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AreaSettingsComponent } from './dashboard/settings/components/area-settings/area-settings.component';
import { CreateAreaDialogComponent } from './dashboard/settings/components/area-settings/area-settings.component';
import { DeleteAreaDialogComponent } from './dashboard/settings/components/area-settings/area-settings.component';
import { EditAreaDialogComponent } from './dashboard/settings/components/area-settings/area-settings.component';
import { DailyViewComponent } from './dashboard/statistics/components/daily-view/daily-view.component';
import { WeeklyViewComponent } from './dashboard/statistics/components/weekly-view/weekly-view.component';
import { TrendsViewComponent } from './dashboard/statistics/components/trends-view/trends-view.component';
import { ExportViewComponent } from './dashboard/statistics/components/export-view/export-view.component';
import { CrossViewComponent } from './dashboard/statistics/components/cross-view/cross-view.component';
import {OverviewViewComponent} from './dashboard/statistics/components/overview-view/overview-view.component';
import { GrafanaChartsViewComponent } from './dashboard/statistics/components/grafana-charts-view/grafana-charts-view.component';
import { LiveViewComponent } from './dashboard/live-view/live-view.component';
import { DateRangePickerSelection } from './dashboard/statistics/components/compare-with/custom-date-picker';
import {CompetitionViewComponent} from './dashboard/statistics/components/competition-view/competition-view.component';
import { CompetitionEmbeddedComponent } from './dashboard/statistics/components/competition-view/competition-embedded/competition-embedded.component';
import {ScrollingDirective} from './shared/directives/scrolling.directive';

// import { NgChartsModule } from 'ng2-charts';

export const UTC_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
  },
};
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

// @Injectable()
// export class RuDateAdapter extends NativeDateAdapter {
//   constructor() {
//     super('gb', new Platform());
//   }
//
//   getFirstDayOfWeek(): number {
//     return 1;
//   }
// }
Chart.register(ChartDataLabels);
// Chart.plugins.unregister(ChartDataLabels);
// Chart.register(ColorSchemes);
// @Pipe({ name: 'safe' })
// export class SafePipe implements PipeTransform {
//   constructor(private sanitizer: DomSanitizer) { }
//   transform(url) {
//     return this.sanitizer.bypassSecurityTrustResourceUrl(url);
//   }
// }

@NgModule({
  declarations: [
    ScrollingDirective,
    SafePipe,
    AppComponent,
    FullComponent,

    AppHeaderComponent,
    AppBlankComponent,
    SpinnerComponent,
    AppSidebarComponent,
    LoginComponent,
    StatisticsComponent,
    SettingsComponent,
    RegisterComponent,
    ForgotComponent,
    ConfirmEmailComponent,
    ChangePasswordComponent,
    FooterComponent,
    PrivacyComponent,
    ServiceTermsComponent,
    EmptyComponent,
    GoogleComponent,
    HeaderExtraComponent,
    TopBarSubComponent,
    CompareWithComponent,
    LeaveDialog,
    AcceptInviteComponent,
    InviteUserDialogComponent,
    DeleteUserDialogComponent,
    LoadingComponent,
    VenueSettingsComponent,
    StandaloneLivePageComponent,
    AreaSettingsComponent,
    CreateAreaDialogComponent,
    DeleteAreaDialogComponent,
    EditAreaDialogComponent,
    DailyViewComponent,
    WeeklyViewComponent,
    TrendsViewComponent,
    ExportViewComponent,
      CompetitionViewComponent,
    CrossViewComponent,
    GrafanaChartsViewComponent,
    LiveViewComponent,
    // MatDialogModule,
    // DetailedMetricComponent,
    MyProfileSettingsComponent,
    UsersSettingsComponent,
    DateRangePickerSelection,
    CompetitionEmbeddedComponent,
    OverviewViewComponent
  ],
  imports: [
    // NgChartsModule,
    MatMomentDateModule,
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    HttpClientModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule.forRoot(AppRoutes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy',
      // enableTracing: true,
    }),
    // MarkdownModule.forRoot(),
    // EyezillaChartsModule,
    // CalendarModule.forRoot({
    //   provide: DateAdapter,
    //   useFactory: adapterFactory
    // }),
    // Unused_influencerModule,
    // NgApexchartsModule,
    NgStrapiAuthModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    // Unused_influencerModule
  ],
  providers: [
    SafePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    // { provide: LOCALE_ID, useValue: 'en-GB'},
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },

    // {provide: DateAdapter, useClass: RuDateAdapter},
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NgStrapiJwtInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NgStrapiErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // constructor(private dateAdapter: DateAdapter<Date>) {
  //   this.dateAdapter.setLocale('pt-BR');
  // }
}
