<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<!--<div class="user-profile-profile" style="background: url(assets/images/background/birdland_banner_new.png) no-repeat; background-size: cover;">-->
<!--    &lt;!&ndash; User profile image &ndash;&gt;-->
<!--    <div class="profile-img">-->
<!--&lt;!&ndash;        <img src="assets/images/users/pizza-zilla-kevin-middleton.jpg" alt="user-profile"> &ndash;&gt;-->
<!--    </div>-->
<!--    &lt;!&ndash; User profile text&ndash;&gt;-->
<!--    &lt;!&ndash; ============================================================== &ndash;&gt;-->
<!--    &lt;!&ndash; Profile - style you can find in header.scss &ndash;&gt;-->
<!--    &lt;!&ndash; ============================================================== &ndash;&gt;-->
<!--    <div class="profile-text">-->
<!--        <a [matMenuTriggerFor]="sdprofile" class=""> Birdland-->
<!--            <i class="ti-angle-down font-12 m-l-5"></i>-->
<!--        </a>-->
<!--    </div>-->
<!--    <mat-menu #sdprofile="matMenu" class="mymegamenu">-->
<!--        <button mat-menu-item>-->
<!--            <mat-icon>settings</mat-icon> Settings-->
<!--        </button>-->
<!--        <button mat-menu-item>-->
<!--            <mat-icon>account_box</mat-icon> Company Profile-->
<!--        </button>-->
<!--        <button mat-menu-item>-->
<!--            <mat-icon>notifications_off</mat-icon> Disable notifications-->
<!--        </button>-->
<!--    </mat-menu>-->
<!--</div>-->

<mat-nav-list
  appAccordion
  fxLayout="column"
  fxLayoutAlign="start"
  style="height: 100%; min-height: 415px;"
>
  <ng-container *ngFor="let menuitem of menuItems">
    <mat-list-item
      appAccordionLink
      routerLinkActive="selected"
      group="{{ menuitem.state }}"
      (click)="scrollToTop(menuitem.type)"
      *ngIf="menuitem.type !== 'saperator'"
      [class.prevent-pointer]="
        menuitem.type === 'info' || menuitem.type === 'info-img'
      "
    >
      <a
        id="{{ assignTagId(menuitem.name) }}"
        class=""
        appAccordionToggle
        [routerLink]="['/', menuitem.state]"
        *ngIf="menuitem.type === 'link'"
      >
        <mat-icon>{{ menuitem.icon }}</mat-icon>
        <span>{{ menuitem.name }}</span>
        <span fxFlex></span>
        <span
          class="label label-{{ badge.type }}"
          *ngFor="let badge of menuitem.badge"
          >{{ badge.value }}</span
        >
      </a>

      <!-- Current Logout menu item -->
      <a
        id="{{ assignTagId(menuitem.name) }}"
        class=""
        appAccordionToggle
        (click)="callFunction(menuitem.state)"
        *ngIf="menuitem.type === 'func'"
      >
        <mat-icon>{{ menuitem.icon }}</mat-icon>
        <span>{{ menuitem.name }}</span>
        <span fxFlex></span>
        <span
          class="label label-{{ badge.type }}"
          *ngFor="let badge of menuitem.badge"
          >{{ badge.value }}</span
        >
      </a>

      <a
        id="{{ assignTagId(menuitem.name) }}"
        class=""
        appAccordionToggle
        href="{{ menuitem.state }}"
        *ngIf="menuitem.type === 'extLink'"
      >
        <mat-icon>{{ menuitem.icon }}</mat-icon>
        <span>{{ menuitem.name }}</span>
        <span fxFlex></span>
        <span
          class="label label-{{ badge.type }}"
          *ngFor="let badge of menuitem.badge"
          >{{ badge.value }}</span
        >
      </a>

      <a
        id="{{ assignTagId(menuitem.name) }}"
        class=""
        href="{{ menuitem.state }}&user_email={{ email }}&user_nickname={{
          fullName
        }}&user_extraKey={{ fullName }}"
        target="_blank"
        *ngIf="
          menuitem.type === 'extTabLink' && menuitem.name === 'Чат с поддержкой'
        "
      >
        <mat-icon>{{ menuitem.icon }}</mat-icon>
        <span>{{ menuitem.name }}</span>
        <span fxFlex></span>
        <span
          class="label label-{{ badge.type }}"
          *ngFor="let badge of menuitem.badge"
          >{{ badge.value }}</span
        >
      </a>

      <a
        id="{{ assignTagId(menuitem.name) }}"
        class=""
        href="{{ menuitem.state }}"
        target="_blank"
        *ngIf="
          menuitem.type === 'extTabLink' && menuitem.name !== 'Чат с поддержкой'
        "
      >
        <mat-icon>{{ menuitem.icon }}</mat-icon>
        <span>{{ menuitem.name }}</span>
        <span fxFlex></span>
        <span
          class="label label-{{ badge.type }}"
          *ngFor="let badge of menuitem.badge"
          >{{ badge.value }}</span
        >
      </a>

      <a
        id="{{ assignTagId(menuitem.name) }}"
        class="menu-accordion"

        appAccordionToggle
        href="javascript:;"
        *ngIf="menuitem.type === 'sub'"
      >
        <mat-icon>{{ menuitem.icon }}</mat-icon>
        <span>{{ menuitem.name }}</span>
        <span fxFlex></span>
        <span
          class="label label-{{ badge.type }}"
          *ngFor="let badge of menuitem.badge"
          >{{ badge.value }}</span
        >
        <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
      </a>

      <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'">
        <mat-list-item
          *ngFor="
            let childitem of menuitem.children;
            let j = index;
            let i = childitem
          "
          routerLinkActive="selected"
        >
          <a
            [routerLink]="['/', childitem.state]"
            *ngIf="childitem.type === 'link'"
            class=""
            routerLinkActive="selected"
            (click)="itemSelect[i] = j"
            ><mat-icon>{{ childitem.icon }}</mat-icon>{{ childitem.name }}</a
          >
        </mat-list-item>

        <mat-list-item
          *ngFor="
            let childitem of menuitem.children;
            let j = index;
            let i = childitem
          "
        >
          <a
            class=""
            href="javascript: void(0);"
            *ngIf="childitem.type === 'subchild'"
            (click)="itemSelect[i] = j"
            [ngClass]="j == itemSelect[i] ? 'selected' : ''"
          >
            <span>{{ childitem.name }}</span>
            <span fxFlex></span>
            <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
          </a>

          <mat-nav-list
            class="child-sub-item"
            *ngIf="childitem.type === 'subchild'"
          >
            <mat-list-item
              *ngFor="let child of childitem.subchildren"
              routerLinkActive="selected"
            >
              <a
                [routerLink]="[
                  '/',
                  menuitem.state,
                  childitem.state,
                  child.state
                ]"
                routerLinkActive="selected"
                >{{ child.name }}</a
              >
            </mat-list-item>
          </mat-nav-list>
        </mat-list-item>
      </mat-nav-list>

      <!--            <div class="saperator text-muted" *ngIf="menuitem.type === 'info'"   >-->
      <!--                            <span style="white-space: break-spaces;margin-left: 12px;">{{ menuitem.name }}</span>-->
      <!--            </div>-->
      <div
        class="saperator text-muted img-container"
        *ngIf="menuitem.type === 'info-img'"
        style="cursor: default; pointer-events: none"
      >
        <!--		        <img src="/assets/images/customer/marugame.jpg" class="light-logo" style="height: 48px;" alt="homepage">-->
        <img alt="homepage" src="{{ brandLogo }}" class="client-logo" />
        <!--		        <span style="white-space: break-spaces;margin-left: 12px;">{{ menuitem.name }}</span>-->
      </div>

      <div
        class="saperator text-muted"
        *ngIf="menuitem.type === 'info'"
        style="cursor: default; pointer-events: none"
      >
        <!--		        <img src="/assets/images/customer/marugame.jpg" class="light-logo" style="height: 48px;" alt="homepage">-->
        <!--		        <img alt="homepage" src="assets/images/0.5x/full_color@0.5x.png" class="large-logo">-->
        <span style="white-space: break-spaces; margin-left: 12px">{{
          menuitem.name
        }}</span>
        <span class="version-data">v3.8.4</span>
      </div>
    </mat-list-item>
    <!--info-img-->
    <!--        <mat-list-item appAccordionLink routerLinkActive="selected"-->
    <!--                       group="{{menuitem.state}}" (click)="scrollToTop()" fxFlex="grow" *ngIf="menuitem.type === 'saperator'">-->
    <mat-list-item
      appAccordionLink
      routerLinkActive="selected"
      group="{{ menuitem.state }}"
      fxFlex="grow"
      *ngIf="menuitem.type === 'saperator'"
      style="cursor: default"
    >
      <div class="saperator text-muted" *ngIf="menuitem.type === 'saperator'">
        <!--            <span>{{ menuitem.name }}</span>-->
      </div>
    </mat-list-item>
  </ng-container>
</mat-nav-list>
<!--<span class="trademark text-muted">© Eyezilla, {{currentYear}}</span>-->
