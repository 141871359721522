<mat-card class="pegasus-card mat-card-top-panel-override">
  <div
    fxFlex
    fxLayout="column wrap"
    fxLayoutAlign="space-between center no-wrap"
    style="
      width: 100%;
      height: fit-content;
      flex-wrap: nowrap;
      padding: 10px 24px;
    "
  >
    <div
      fxFlex
      fxLayout="row wrap"
      fxLayoutAlign="space-between center no-wrap"
      style="font-size: 15px; font-weight: 400;width: 100%"
    >
<!--      <span style="white-space: nowrap"> Date from </span>-->
<!--      <strong id="header-date-start" style="margin: 0 5px">-->
<!--        {{ dateRange.start || 'Not defined' }}-->
<!--      </strong>-->
<!--      to-->
<!--      <strong id="header-date-end" style="margin: 0 5px">-->
<!--        {{ dateRange.end || 'Not defined' }}-->
<!--      </strong>-->

<!--      &lt;!&ndash; Open calendar rightsidebar button &ndash;&gt;-->
<!--      <button-->
<!--        id="edit-date"-->
<!--        mat-icon-button-->
<!--        style="margin-left: 10px"-->
<!--        aria-label="Date configuration"-->
<!--        alt="openSidebar"-->
<!--        (click)="openRightSidebar()"-->
<!--      >-->
<!--        <img-->
<!--          src="assets/images/pegasus-icons/Edit.svg"-->
<!--          alt="openSidebar"-->
<!--          class="settings-cog heading-color"-->
<!--        />-->
<!--      </button>-->

      <div
        fxFlex
        fxLayoutAlign="space-between center"
        style="margin-left: 20px"
      >
        <!-- Venue List multiple select -->
        <mat-form-field>
          <mat-label>Venues</mat-label>
          <mat-select
            [value]="allVenues"
            multiple
            (selectionChange)="selectionTrendsGroup($event)"
          >
            <ng-container *ngFor="let venue of allVenues">
              <mat-option [value]="venue">{{ venue.name }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div style="margin-left: 20px" *ngIf="!isDemoPos">

      </div>

    </div>

    <div
            fxFlex
            fxLayout="row wrap"
            fxLayoutAlign="space-between center no-wrap"
            style="font-size: 15px; font-weight: 400;width: 100%"
    >
      <span style="white-space: nowrap">
<!--        <button mat-button >Download/Export</button>-->
<!--        <button mat-raised-button color="primary" (click)="downloadXLSX()" [disabled]="exportInProgress">-->
<!--          Export-->
<!--          <mat-icon *ngIf="!exportInProgress">download</mat-icon>-->
<!--          <mat-spinner *ngIf="exportInProgress" [diameter]="18" class="download-spinner"></mat-spinner>-->
<!--        </button>-->
      </span>
    </div>
  </div>

</mat-card>

<!-- Used for E2E testing purposes only -->
<div id="timeslots-selected" style="display: none">
  {{ statisticsParams.timeslotsSelected.join(',') }}
</div>

<!-- GRAFANA VIEW -->
<app-grafana-charts-view
  data-testid="app-grafana-charts-view"
  [venueData]="venueData"
  [statisticsParams]="statisticsParams"
  [trendsGroup]="exportGroup"
></app-grafana-charts-view>
