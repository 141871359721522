import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  OnDestroy,
  Pipe,
  PipeTransform, Input,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgStrapiAuthConfig } from '../../services/strapi/auth/ng-strapi-auth-config';
import { environment } from '../../../environments/environment';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
  SafeScript,
  SafeStyle,
  SafeUrl,
} from '@angular/platform-browser';
import { animate, style, transition, trigger } from '@angular/animations';
import moment from 'moment';
import {ChartsData, Venue} from '../../utils/CommonInterfaces';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(
    value: string,
    type: string
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }
}

@Component({
  selector: 'app-standalone-live-page',
  templateUrl: './standalone-live-page.component.html',
  styleUrls: ['./standalone-live-page.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0.5, transform: 'translateX(80%)' }),
        // animate('200ms linear'),
        // style({ opacity: 1, display: 'flex' }),
        animate(
          '500ms ease-in-out',
          style({ opacity: 1, transform: 'translateX(0%)' })
        ),
      ]),
      transition(':leave', [
        style({
          position: 'fixed',
          left: '15px',
          'margin-left': '15px',
          'padding-right': '15px',
        }),
        animate(
          '300ms ease-in-out',
          style({ opacity: 0, transform: 'translateX(-80%)' })
        ),
        // style({ opacity: 0}),
        // style({ display: 'none' }),
      ]),
      // transition(
      //     ':enter',
      //     [
      //       style({ transform: 'translateX(150%)' }),
      //       animate('1s ease-in',
      //           style({ transform: 'translateX(0)' }))
      //     ]
      // )
      // ,
      // transition(
      //     ':leave',
      //     [
      //       // style({ transform: 'translateX(0)' }),
      //       animate('1s ease-out',
      //           style({ transform: 'translateX(-150%)' }))
      //     ]
      // )
    ]),
  ],
})
export class StandaloneLivePageComponent implements OnInit, OnDestroy {

  @Input() venuesList: Venue[];
  @Input() isEmbedded: boolean;
  public competitionData;
  public displayCompetition = false;
  public competitionAutoScrollSpeed: number;
  private isDemo = false;
  @Input() set venueData(venueData: Venue) {
    console.log('t1 got venue id update', venueData, this.isEmbedded);
    if (venueData) {
      this.isEmbedded = true;
      this._venueData = venueData;
      this.isDemo = this._venueData.live_screen_users[0].description === 'demo_screen_only';
      if(this.isDemo){
        this.chartsData.durations[0].name = 'Order';
        this.chartsData.durations[1].name = 'Kitchen';
        this.chartsData.durations[2].name = 'Payment';

      }
      this.startFetchingInterval();
    }

  }
  private _venueData: Venue;
  venueName: string;
  private apiKey: string;
  private deviceId: string;
  displayError = false;
  errorMessage = 'Please provide valid apiKey and deviceId';
  private apiUrl: string;
  public liveScreenReady = false;
  private liveScreenData: {
    grafanaData: any;
    queryParams: any;
    screenData: any;
    timestamp: any;
  };
  private liveScreenDataManager:
    | LiveScreenDataManager
    | LiveScreenDataManagerDev;
  private getLiveScreenUrlIntervalID: ReturnType<typeof setInterval>;
  private sliderIndexIntervalID: ReturnType<typeof setInterval>;
  private competitionInterval: ReturnType<typeof setInterval>;


  public sliderIndex = 0;
  public chartsData: ChartsData = {
    durations: [
      {
        metricId: 'yusen',
        name: 'Yusen',
        subtitle: 'station duration',
        value: 'n/a',
        status: 'n/a',
        arrow: 'none',
        busyness: 0,
        alert: false,
        alertStartTime: null,
        alertCoolDownStartTime: null,
      },
      {
        metricId: 'tempura',
        name: 'Tempura',
        subtitle: 'station duration',
        value: 'n/a',
        status: 'n/a',
        arrow: 'none',
        busyness: 0,
        alert: false,
        alertStartTime: null,
        alertCoolDownStartTime: null,
      },
      {
        metricId: 'cashier',
        name: 'Cashier',
        subtitle: 'station duration',
        value: 'n/a',
        arrow: 'none',
        status: 'n/a',
        busyness: 0,
        alert: false,
        alertStartTime: null,
        alertCoolDownStartTime: null,
      },
    ],
    totalDuration: {
      metricId: 'totalDuration',
      title: 'Average duration',
      subtitle: 'average duration',
      value: 'n/a',
      arrow: 'none',
      status: 'n/a',
      busyness: 0,
      alert: false,
      alertStartTime: null,
      alertCoolDownStartTime: null,
    },
    slider: [
      {
        metricId: 'transactions',
        type: 'comparison',
        timeGap: 'n/a',
        title: 'Transactions',
        value: 'n/a',
        lastWeek: 'n/a',
        chain: 'n/a',
        record: 'n/a',
      },
      {
        metricId: 'covers',
        type: 'comparison',
        timeGap: 'n/a',
        title: 'Covers',
        value: 'n/a',
        lastWeek: 'n/a',
        chain: 'n/a',
        record: 'n/a',
      },
      // {
      //   type: 'events',
      //   events: []
      // }
    ],
  };

  constructor(
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    @Inject('config') private config: NgStrapiAuthConfig,
  ) {
    console.log('t1 constructor', this.isEmbedded);
    if (this.config && this.config.apiUrl) {
      this.apiUrl = this.config.apiUrl;
    } else {
      const err = '[NgStrapiAuth]: no api url provided';
      console.error(err);
      throw new Error('[NgStrapiAuth]: no api url provided');
    }
  }

  private startFetchingInterval = async () => {
    if (this.getLiveScreenUrlIntervalID) {
        clearInterval(this.getLiveScreenUrlIntervalID);
    }
    if (this.sliderIndexIntervalID) {
        clearInterval(this.sliderIndexIntervalID);
    }
    if (this.competitionInterval) {
        clearInterval(this.competitionInterval);
    }
    console.log('_venueData', this._venueData.live_screen_users[0]?.description);
    this.liveScreenDataManager = environment.production && !this.isDemo
      ? new LiveScreenDataManager()
      : new LiveScreenDataManagerDev();

    await this.getLiveScreenData();
    if (!this.liveScreenData) {
      setTimeout(this.startFetchingInterval, 10000);
    }
    console.log('this.liveScreenData', this.liveScreenData);

    this.venueName = this.liveScreenData.screenData.venue.name;

    this.liveScreenDataManager.processExistingData(this.chartsData);
    // Set Get Live Screen Url Interval
    const getLiveScreenUrlIntervalDurationInSeconds =
      this.liveScreenData.screenData.livescreen_config.data_update_sec * 1000 ||
      30 * 1000;

    this.getLiveScreenUrlIntervalID = setInterval(async () => {
      await this.getLiveScreenData();
      this.liveScreenDataManager.processExistingData(this.chartsData);
    }, getLiveScreenUrlIntervalDurationInSeconds);

    // Set Slider Index Interval
    const sliderIndexIntervalDurationInSeconds =
      this.liveScreenData.screenData.livescreen_config.slider_duration_sec *
        1000 || 30 * 1000;

    this.sliderIndexIntervalID = setInterval(() => {
      this.sliderIndex =
        this.chartsData.slider.length - 1 === this.sliderIndex
          ? 0
          : this.sliderIndex + 1;
    }, sliderIndexIntervalDurationInSeconds || 15000);

    if (false) { // force remove competition (!this.isEmbedded)
      // Set Competition Interval
      const competitionDisplayRate_sec =
          this.liveScreenData.screenData.livescreen_config.competitionDisplayRate_sec *
          1000 || 30 * 1000;

      const competitionDisplayDuration_sec =
          this.liveScreenData.screenData.livescreen_config.competitionDisplayDuration_sec *
          1000 || 30 * 1000;

      // this.displayCompetition = true;
      this.competitionInterval = setInterval(() => {
        this.displayCompetition = true;
        setTimeout(() => {
          this.displayCompetition = false;
        }, competitionDisplayDuration_sec);
      }, competitionDisplayRate_sec + competitionDisplayDuration_sec || 15000);

      this.competitionAutoScrollSpeed =
          this.liveScreenData.screenData.livescreen_config.competitionAutoScrollSpeed_ms || 2500;
    }

  }


  private async getLiveScreenData(): Promise<void> {
    if (!this.isEmbedded) {
      await this.getLiveScreenDataStandalone();
    } else {
      await this.getLiveScreenDataDashboard();
    }
  }

  private async getLiveScreenDataStandalone(): Promise<void> {
    const payload = {
      apiKey: this.apiKey,
      deviceId: this.deviceId,
    };

    try {
      const res: any = await this.httpClient
          .post(this.apiUrl + '/live-screen-users/get-live-screen-data', payload)
          .toPromise();

      res.timestamp = moment();

      this.liveScreenData = res;
      this.competitionData = res.competitionData;
      this.liveScreenReady = true;
      if (this.liveScreenDataManager) {
        this.liveScreenDataManager.updateRecentPayload(this.liveScreenData);
      }
    } catch (e) {
      console.log('e', e);

      this.displayError = true;
      this.errorMessage = 'Cannot get live screen data';

      setTimeout(() => {
        window.location.reload();
      }, 60000);
    }
  }

  private async getLiveScreenDataDashboard(): Promise<void> {
    console.log('getLiveScreenDataDashboard');
    try {
      const res: any = await this.httpClient
          .post(this.apiUrl + '/live-screen-users/get-live-screen-data-embedded', {
            venueId: this._venueData.id
          })
          .toPromise();

      res.timestamp = moment();

      this.liveScreenData = res;
      this.liveScreenReady = true;
      this.displayError = false;
      if (this.liveScreenDataManager) {
        this.liveScreenDataManager.updateRecentPayload(this.liveScreenData);
      }
    } catch (e) {
      this.liveScreenReady = false;
      this.displayError = true;
      this.errorMessage = 'Cannot get live screen data';

      // setTimeout(() => {
      //   window.location.reload();
      // }, 60000);
    }
  }

  ngOnInit(): void {
    if (!this.isEmbedded) {
      this.route.queryParams.subscribe((params) => {
        this.apiKey = params['apiKey'];
        this.deviceId = params['deviceId'];
        this.displayError = !this.apiKey || !this.deviceId;
        const randomTimeout = environment.production ? Math.floor(Math.random() * 10000) : 0; // 0 - 10 seconds

        setTimeout(() => {
          this.startFetchingInterval();
        }, randomTimeout);

      });
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.getLiveScreenUrlIntervalID);
    clearInterval(this.sliderIndexIntervalID);
    clearInterval(this.competitionInterval);
  }
}

class LiveScreenDataManager {
  name = 'test';
  maxAlertDuration = 5; // minutes
  alertCoolDown = 5; // minutes
  recentPayload = null;
  constructor() {}

  /**
   * Returns time in m:ss format
   *
   * @param time in seconds
   * @returns string in m:ss format
   */
  formatTimeToMSS(time: number): string {
    return moment(time * 1000).format('m:ss');
  }

  processExistingData(chartsData: ChartsData): void {
    if (this.recentPayload === null) {
      return;
    }

    console.log('this.recentPayload', this.recentPayload);

    Object.keys(chartsData).forEach((sectionId) => {
      const sectionData = chartsData[sectionId];
      if (sectionId === 'durations') {
        // section for durations
        sectionData.forEach((metric) => {
          this.processDurationMetric(metric, this.recentPayload);
        });
      } else if (sectionId === 'totalDuration') {
        this.processDurationMetric(sectionData, this.recentPayload);
      } else if (sectionId === 'slider') {
        // section for slider
        sectionData.forEach((metric) => {
          if (metric.type === 'comparison') {
            this.processComparisonMetric(metric, this.recentPayload);
          }
        });
      }
    });
  }

  updateRecentPayload(payload) {
    this.recentPayload = payload;
  }

  private processDurationMetric(metric, recentPayload: any) {
    // metricId: string;
    // arrow: 'none' | 'up' | 'down';
    // alert: boolean;
    // alertStartTime: null | moment.Moment;
    // alertCoolDownStartTime: null | moment.Moment;
    // busyness: 0|1|2|3|4|5|6|7|8|9|10;
    // subtitle: string;
    // name: string;
    // value: string;
    // status: 'n/a' | 'good' | 'bad' | 'critical';

    // Value and status processing
    const { value, arrow, busyness, status } = this.processDurationValue(
      metric,
      this.recentPayload
    );
    metric.value = value;
    metric.arrow = arrow;
    metric.busyness = busyness;
    metric.status = status;

    // Alert state processing
    if (
      metric.alert &&
      moment().diff(metric.alertStartTime, 'minutes') > this.maxAlertDuration
    ) {
      metric.alert = false;
      metric.alertStartTime = null;
      metric.alertCoolDownStartTime = moment();
    }

    if (
      metric.alertCoolDownStartTime &&
      moment().diff(metric.alertCoolDownStartTime, 'minutes') >
        this.alertCoolDown
    ) {
      metric.alertCoolDownStartTime = null;
    }

    if (metric.alertCoolDownStartTime === null) {
      const { alert, alertStartTime } = this.processDurationAlert(
        metric,
        this.recentPayload
      );
      metric.alert = alert;
      metric.alertStartTime = alertStartTime;
    }
  }

  private processDurationAlert(metric, recentPayload: any) {
    // When traffic is at or above 90%
    // and duration above the threshold kick in the card animation loop

    if (metric.status === 'critical' && metric.busyness >= 9) {
      return { alert: true, alertStartTime: moment() };
    } else {
      return { alert: false, alertStartTime: null };
    }
  }

  private processDurationValue(metric, recentPayload: any) {
    const result = {
      value: 'n/a',
      arrow: 'none',
      busyness: 0,
      status: 'n/a',
    };
    const screenConfig = recentPayload.screenData.livescreen_config;

    if (recentPayload.grafanaData[metric.metricId].length > 0) {
      const value = recentPayload.grafanaData[metric.metricId][0].average;

      result.status =
        value < screenConfig[metric.metricId + '_duration_sec_good']
          ? 'good'
          : value < screenConfig[metric.metricId + '_duration_sec_bad']
          ? 'bad'
          : 'critical';
      result.value = this.formatTimeToMSS(value);
      // value < 60
      //   ? Math.round(value).toString() + 's'
      //   : Math.round((value / 60) * 10) / 10 + 'm';
    }

    if (recentPayload.grafanaData[metric.metricId].length > 1) {
      result.arrow =
        recentPayload.grafanaData[metric.metricId][0].average >
        recentPayload.grafanaData[metric.metricId][1].average
          ? 'up'
          : 'down';
    }

    if (recentPayload.grafanaData.station_utilisation.length > 0) {
      let metricId = metric.metricId;
      if (metric.metricId === 'totalDuration') {
        metricId = 'total';
      }
      result.busyness = Math.round(
        recentPayload.grafanaData.station_utilisation[0][metricId] * 10
      );
    }
    return result;
  }

  private processComparisonMetric(metric, recentPayload: any) {
    // metricId: string;
    // chain: 'n/a' | number;
    // timeGap: string;
    // record: 'n/a' | number;
    // type: 'comparison' | 'events';
    // title: string;
    // value: 'n/a' | number;
    // lastWeek: 'n/a' | number

    // Time gap processing
    const { timeGap } = this.processComparisonTimeGap(
      metric,
      this.recentPayload
    );
    metric.timeGap = timeGap;

    // Value processing
    const { value, chain, record, lastWeek } = this.processComparisonValue(
      metric,
      this.recentPayload
    );
    metric.value = value;
    metric.chain = chain;
    metric.record = record;
    metric.lastWeek = lastWeek;
  }

  private processComparisonTimeGap(metric, recentPayload: any) {
    const result = {
      timeGap: 'n/a',
    };

    if (
      recentPayload.grafanaData.venue_covers_transactions_last_hour.length > 0
    ) {
      const now = moment(
        recentPayload.grafanaData.venue_covers_transactions_last_hour[0].time
      );
      const currentHourStart = now.format('HH:mm');
      const currentHourEnd = now.clone().add(1, 'hour').format('HH:mm');
      result.timeGap = currentHourStart + ' - ' + currentHourEnd;
    }

    return result;
  }

  private processComparisonValue(metric, recentPayload: any) {
    // generate random value but value should be less than others or equal to them
    console.log('processComparisonValue', metric, recentPayload);
    const metricIdFixed =
      metric.metricId === 'transactions' ? 'bills' : metric.metricId;

    const result = {
      value: 0,
      chain: 0,
      record: 0,
      lastWeek: 0,
    };
    console.log(
      'recentPayload.grafanaData[metricId + \'_record\'][0]',
      recentPayload.grafanaData[metric.metricId + '_record'][0]
    );
    if (recentPayload.grafanaData[metric.metricId + '_record'].length > 0) {
      result.record = Math.round(
        Number(
          recentPayload.grafanaData[metric.metricId + '_record'][0][
            'rolling_' + metricIdFixed
          ]
        )
      );
    }
    if (
      recentPayload.grafanaData['venue_covers_transactions_last_hour'].length >
      0
    ) {
      result.value = Math.round(
        Number(
          recentPayload.grafanaData['venue_covers_transactions_last_hour'][0][
            'rolling_' + metricIdFixed
          ]
        )
      );
    }
    if (
      recentPayload.grafanaData['venue_covers_transactions_last_hour_chain']
        .length > 0
    ) {
      result.chain = Math.round(
        Number(
          // ['rolling_' + metricId]
          recentPayload.grafanaData[
            'venue_covers_transactions_last_hour_chain'
          ][0][metricIdFixed]
        )
      );
    }
    if (
      recentPayload.grafanaData['venue_covers_transactions_last_hour_last_week']
        .length > 0
    ) {
      result.lastWeek = Math.round(
        Number(
          recentPayload.grafanaData[
            'venue_covers_transactions_last_hour_last_week'
          ][0]['rolling_' + metricIdFixed]
        )
      );
    }

    return result;
  }
}

// @ts-ignore
class LiveScreenDataManagerDev extends LiveScreenDataManager {
  // private processDurationAlert(metric, recentPayload: any) {
  //   const result = Math.random() < 0.3
  //       ? {alert: true, alertStartTime: moment()}
  //       : {alert: false, alertStartTime: null};
  //   return result;
  // }

  private processDurationValue(metric, recentPayload: any) {
    const randomValue = Math.floor(Math.random() * 100);
    const screenConfig = recentPayload.screenData.livescreen_config;
    return {
      value: this.formatTimeToMSS(randomValue),
      arrow: Math.random() > 0.5 ? 'up' : 'down',
      busyness: Math.floor((Math.random() + 0.05) * 10),
      status:
        randomValue < screenConfig[metric.metricId + '_duration_sec_good']
          ? 'good'
          : randomValue < screenConfig[metric.metricId + '_duration_sec_bad']
          ? 'bad'
          : 'critical',
    };
  }

  // private processDurationStatus(metric, recentPayload: any) {
  //   const states = ['good', 'bad', 'critical'];
  //
  //   const random = Math.floor(Math.random() * states.length);
  //   return {
  //     status: states[random],
  //   };
  // }

  private processComparisonTimeGap(metric, recentPayload: any) {
    const now = moment();
    const currentHourStart = moment().startOf('hour').format('HH:mm');
    const currentHourEnd = moment()
      .endOf('hour')
      .add(1, 'minute')
      .format('HH:mm');

    return { timeGap: currentHourStart + ' - ' + currentHourEnd };
  }

  private processComparisonValue(metric, recentPayload: any) {
    // generate random value but value should be less than others or equal to them
    const value = Math.floor(Math.random() * 100);
    return {
      value: value,
      chain: value + Math.floor(Math.random() * 100),
      record: value + Math.floor(Math.random() * 100),
      lastWeek: value + Math.floor(Math.random() * 100),
    };
  }
}
// @ts-ignore
